import { Text } from '@/components/Typography'
import * as SelectPrimitive from '@radix-ui/react-select'
import {
  IconCheck,
  IconChevronDown,
  IconChevronUp,
  IconInfoCircle,
} from '@tabler/icons-react'
import { type ComponentProps, forwardRef } from 'react'
import { Field } from '../Field'
import {
  InputError,
  SelectContainer,
  SelectContent,
  SelectIcon,
  SelectInnerContainer,
  SelectItemContainer,
  SelectLabel,
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectViewPort,
  Spacer,
} from './styles'

type SelectProps = ComponentProps<typeof SelectPrimitive.Root> & {
  options: { label: string; value: string }[]
  label: string
  error?: string
  className?: string
}

export const Select = forwardRef<HTMLButtonElement, SelectProps>(
  ({ children, options, label, error, ...props }, forwardedRef) => {
    return (
      <Field label={label} error={error}>
        <SelectRoot {...props}>
          <SelectTrigger ref={forwardedRef} id={label}>
            <SelectValue placeholder="Selecione uma opção" />
            <SelectIcon>
              <IconChevronDown />
            </SelectIcon>
          </SelectTrigger>

          <SelectContent>
            <SelectPrimitive.ScrollUpButton>
              <IconChevronUp />
            </SelectPrimitive.ScrollUpButton>
            <SelectViewPort>
              <SelectPrimitive.Group>
                {options.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectPrimitive.Group>
            </SelectViewPort>
            <SelectPrimitive.ScrollDownButton />
            <SelectPrimitive.Arrow />
          </SelectContent>
        </SelectRoot>
      </Field>
    )
  },
)

type SelectItemProps = ComponentProps<typeof SelectPrimitive.Item>

export const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <SelectItemContainer {...props} ref={forwardedRef}>
        <SelectPrimitive.ItemText asChild>
          <Text>{children}</Text>
        </SelectPrimitive.ItemText>
        <SelectPrimitive.ItemIndicator>
          <IconCheck />
        </SelectPrimitive.ItemIndicator>
      </SelectItemContainer>
    )
  },
)
