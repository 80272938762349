import { onlyNumbers, validateCPF } from '@/utils'
import { isValidNumber } from 'libphonenumber-js'
import { z } from 'zod'

export const contactPidFormSchema = z
  .object({
    cpf: z
      .string()
      .min(14, { message: 'CPF deve ter 11 dígitos' })
      .max(14, { message: 'CPF deve ter 11 dígitos' })
      .transform((cpf) => onlyNumbers(cpf))
      .refine((cpf) => validateCPF(cpf), { message: 'Informe um CPF válido' }),
    fullName: z
      .string()
      .min(10, { message: 'Informe seu nome completo' })
      .max(200, { message: 'O nome deve ter menos de 200 caracteres' }),
    email: z.string().email({ message: 'Informe um email válido' }),
    emailConfirmation: z.string().email({ message: 'Informe um email válido' }),
    phone: z
      .string()
      .min(15, { message: 'O número deve ter 11 dígitos' })
      .max(15, { message: 'O número deve ter 11 dígitos' })
      .refine((phone) => isValidNumber(phone, 'BR'), {
        message: 'Informe um número de telefone brasileiro válido',
      })
      .transform((phone) => onlyNumbers(phone)),
    bu: z.enum(['benefits', 'expense', 'people'], {
      message: 'Selecione uma das opções',
    }),
  })
  .refine((data) => data.email === data.emailConfirmation, {
    path: ['emailConfirmation'],
    message: 'Os e-mails devem ser iguais',
  })

export type ContactPidFormData = z.infer<typeof contactPidFormSchema>
