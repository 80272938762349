import { zodResolver } from '@hookform/resolvers/zod'
import Head from 'next/head'
import { useRouter } from 'next/router'
import type { FormEvent, ReactElement } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { FooterActions } from '@/components/FooterActions'
import { FormContainer } from '@/components/Form/FormContainer'
import { FormDescription } from '@/components/Form/FormDescription'
import { Select } from '@/components/Form/Select'
import { TextField } from '@/components/Form/TextField'
import { Link } from '@/components/Link'
import { Text, Title } from '@/components/Typography'
import { useAppState } from '@/contexts/AppStateContext'
import { AppLayout } from '@/layouts/AppLayout'
import type { NextPageWithLayout } from '@/pages/_app.page'
import { maskCPF } from '@/utils'
import { formatNationalInputPhoneNumber } from '@/utils/phoneNumberValidation'
import {
  type ContactPidFormData,
  contactPidFormSchema,
} from './contactPidFormSchema'
import { Container } from './styles'

const buOptions = [
  {
    value: 'benefits',
    label: 'Benefícios (Cartão Flash, Saldos de benefícios)',
  },
  { value: 'expense', label: 'Gestão de Despesas / Cartão Corporativo' },
  {
    value: 'people',
    label: 'Gestão de Pessoas / Controle de Jornada / Flash Pessoas',
  },
  // {
  //   value: 'travel',
  //   label: 'Plataforma de Viagens Corporativas / Hospedagens / Passagens',
  // },
]

const Contact: NextPageWithLayout = () => {
  const {
    formState,
    setFormState,
    setCancelModalOpen,
    cancelModalOpen,
    setCurrentStep,
  } = useAppState()

  const router = useRouter()

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<ContactPidFormData>({
    resolver: zodResolver(contactPidFormSchema),
    defaultValues: {
      cpf: maskCPF(formState.cpf),
      fullName: formState.fullName,
      email: formState.email,
      emailConfirmation: formState.emailConfirmation,
      phone: formatNationalInputPhoneNumber(formState.phone),
      bu: formState.bu,
    },
  })

  function handleGoBack(event: FormEvent) {
    event.preventDefault()
    setCurrentStep((prev) => prev - 1)
    router.back()
  }

  async function handleNextStep({
    cpf,
    bu,
    fullName,
    email,
    emailConfirmation,
    phone,
  }: ContactPidFormData) {
    try {
      setFormState((prevState) => ({
        ...prevState,
        cpf,
        bu,
        fullName,
        email,
        emailConfirmation,
        phone,
      }))

      router.push('/contato/mensagem')
      setCurrentStep((prev) => prev + 1)
    } catch (error) {
      router.push('/contato/sinto-muito')
    } finally {
      // setInLS('recaptcha', '')
    }
  }

  // if (!isRequestDataValid) {
  //   return null
  // }

  return (
    <Container>
      <Head>
        <title>Central de ajuda| Flash</title>
      </Head>

      <form onSubmit={handleSubmit(handleNextStep)}>
        <div>
          <FormDescription>
            <Title as="legend" size="headline7">
              Você é um colaborador com produto Flash e precisa de suporte?
            </Title>
            <Text size="body3">
              Preencha o formulário e entraremos em contato o mais breve
              possível.
            </Text>
            <br />
            <Text size="body3">
              Quer saber sobre os nossos horários e canais de atendimento?{' '}
              <Link href="https://beneficios.flashapp.com.br/faq/fale-com-a-gente-time-beneficios">
                Clique aqui.
              </Link>
            </Text>
          </FormDescription>

          <FormContainer>
            <fieldset>
              <TextField
                type="text"
                label="CPF"
                autoComplete="on"
                error={errors.cpf?.message}
                placeholder="000.000.000-00"
                {...register('cpf', {
                  onChange(event) {
                    const { value } = event.target
                    event.target.value = maskCPF(value)
                  },
                })}
              />

              <TextField
                type="text"
                autoComplete="on"
                label="Nome completo"
                error={errors.fullName?.message}
                placeholder="Seu Nome"
                {...register('fullName')}
              />

              <TextField
                type="text"
                label="E-mail para contato"
                autoComplete="on"
                error={errors.email?.message}
                placeholder="seu.email@email.com"
                {...register('email')}
              />

              <TextField
                type="text"
                label="Confirme seu e-mail"
                autoComplete="on"
                error={errors.emailConfirmation?.message}
                placeholder="seu.email@email.com"
                {...register('emailConfirmation')}
              />

              <TextField
                type="tel"
                label="Telefone para contato"
                autoComplete="on"
                error={errors.phone?.message}
                placeholder="(00) 00000-0000"
                {...register('phone', {
                  onChange(event) {
                    const { value } = event.target
                    event.target.value = formatNationalInputPhoneNumber(value)
                  },
                })}
              />

              <Controller
                control={control}
                name="bu"
                render={({ field }) => (
                  <Select
                    label="Sobre o que você deseja falar?"
                    options={buOptions}
                    error={errors.bu?.message}
                    value={formState?.bu}
                    onValueChange={field.onChange}
                    {...field}
                  />
                )}
              />
            </fieldset>
          </FormContainer>
        </div>

        <FooterActions
          cancelModalOpen={cancelModalOpen}
          onCancelModalOpen={() => setCancelModalOpen((prev) => !prev)}
          onHandleGoBack={handleGoBack}
          primaryButtonDisabled={isSubmitting}
          loading={isSubmitting}
        />
      </form>
    </Container>
  )
}

Contact.getLayout = function getLayout(page: ReactElement) {
  return <AppLayout>{page}</AppLayout>
}

export default Contact
