import { AsYouType } from 'libphonenumber-js'

export const formatInputPhoneNumber = (
  phoneNumber: string,
  nationalFormat = false,
) => {
  if (nationalFormat) {
    return formatNationalInputPhoneNumber(phoneNumber)
  }
  return formatInternationalInputPhoneNumber(phoneNumber)
}

export const getUnformattedPhoneNumber = (
  phoneNumber: string,
  nationalFormat = false,
): string => {
  if (nationalFormat) {
    return getUnformattedNationalPhoneNumber(phoneNumber)
  }
  return getUnformattedInternationalPhoneNumber(phoneNumber)
}

export const formatInternationalInputPhoneNumber = (phoneNumber: string) =>
  new AsYouType().input(phoneNumber)

export const formatNationalInputPhoneNumber = (phoneNumber: string) => {
  const unformattedPhoneNumber = getUnformattedNationalPhoneNumber(phoneNumber)
  if (unformattedPhoneNumber.length <= 2) {
    return unformattedPhoneNumber
  }
  return new AsYouType('BR').input(phoneNumber)
}

export const getUnformattedInternationalPhoneNumber = (
  phoneNumber: string,
): string => {
  const formatter = new AsYouType()
  formatter.input(phoneNumber)
  return formatter.getNumberValue() || ''
}

export const getUnformattedNationalPhoneNumber = (
  phoneNumber: string,
): string => {
  const formatter = new AsYouType('BR')
  formatter.input(phoneNumber)
  const phoneNumberObj = formatter.getNumber()
  if (phoneNumberObj) {
    return phoneNumberObj.nationalNumber
  }
  return ''
}
